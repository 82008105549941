import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import AuthLayout from 'src/layouts/AuthLayout'
import { useMutation } from 'react-query'
import { resetUserFn } from 'src/api'
import { countriesPhoneOptions } from 'src/share/constants'
import { BackButton, DarkLogo } from 'src/components/SymanisLogo'
import { useNavigate } from 'react-router-dom'
import { Images } from 'src/assets'
import { motion } from 'framer-motion'

type ResetUserFormInputs = {
  email: string
  countryCode: string
  phone: string
}

function ResetUserPage() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
    control,
  } = useForm<ResetUserFormInputs>()
  const {
    mutate: resetUser,
    isLoading,
    isSuccess: resetSucceeded,
  } = useMutation(resetUserFn)

  const submitResetUserForm = async () => {
    if (!isValid) return

    const { email, countryCode, phone } = getValues()
    resetUser({ email, countryCode, phone })
  }

  if (resetSucceeded) {
    return (
      <div className="flex flex-col px-16 py-10 h-screen bg-hero bg-cover">
        <DarkLogo />
        <div className="flex flex-col space-y-4 max-w-md my-12 p-4 bg-white rounded-lg">
          <Box
            style={{
              gap: 16,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img src={Images.icReset} />
            <Typography fontSize={32}>Reset User</Typography>
            <Typography fontSize={18}>
              A reset link has been sent to your email address
            </Typography>
          </Box>
        </div>
      </div>
    )
  }

  return (
    <AuthLayout>
      <form
        onSubmit={handleSubmit(submitResetUserForm)}
        className="w-[600px] h-[490px] p-8 bg-white rounded-[24px] overflow-hidden"
      >
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.2 }}
        >
          <Box
            style={{
              marginBottom: '30px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div onClick={() => navigate(-1)}>
              <BackButton width="32" height="32" />
            </div>
            <Typography textAlign="center" fontWeight="600" fontSize="32px">
              Reset user
            </Typography>
            <div />
          </Box>
          <Typography
            textAlign="center"
            fontSize="18px"
            sx={{ marginBottom: '30px' }}
          >
            {'Please enter the email and phone number you\nused to register'}
          </Typography>
          <TextField
            {...register('email', { required: true })}
            label="Email"
            fullWidth
            margin="normal"
            required
          />
          <Box
            style={{
              marginBottom: '30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Controller
              name="countryCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onBlur, onChange, ref } }) => (
                <Autocomplete
                  options={countriesPhoneOptions}
                  getOptionLabel={(option) => option.label}
                  ref={ref}
                  onChange={(_, newValue) => {
                    onChange(newValue?.value)
                  }}
                  onBlur={onBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country code"
                      margin="normal"
                      sx={{ width: '180px' }}
                      required
                    />
                  )}
                />
              )}
            />
            <TextField
              {...register('phone', { required: true })}
              label="Phone"
              margin="normal"
              required
            />
          </Box>
          <Button
            sx={{
              '&:hover': { backgroundColor: '#303030' },
              mt: 3,
              textTransform: 'none',
              backgroundColor: '#303030',
              width: '100%',
              height: 48,
              borderRadius: '12px',
            }}
            variant="contained"
            type="submit"
            disabled={isLoading || !isValid}
          >
            {isLoading ? <CircularProgress /> : 'Submit'}
          </Button>
        </motion.div>
      </form>
    </AuthLayout>
  )
}

export default ResetUserPage
