import { env } from 'src/env'

export const BASE_URL = env.API_URL

export const ENDPOINTS = {
  // LOGIN: `login`,
  GET_DATA: 'get-data',
  PHONES: 'phones',
  USERS: 'users',
  EMAILS: 'emails',
  CODES: 'codes',
  SYMBOL_CATEGORIES: 'symbols-categories',
  SECURITY_QUESTIONS: 'security-questions',
  TOKEN: 'token',
  ORGANIZATIONS: 'organizations',
  GROUPS: 'groups',
  LOGS: 'logs',
  EMAIL_HISTORY: 'email-history',
  GENERAL_SETTINGS: 'settings',
  COLORS: 'colors',
  SUBSCRIPTIONS: 'subscriptions',
  SAML: 'saml',
  OAUTH: 'oauth',
}
