import { useEffect, useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { RequireRole } from 'src/routes'
import { USER_ROLES } from 'src/share/constants'
import GroupsTab from './GroupsTab'
import UsersTab from './UsersTab'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import ServiceProviderPage from '../saml/ServiceProvider'
import OAuthClientPage from '../oauth/OAuthClient'
import { useQuery } from 'react-query'
import { getOrgGroupsFn, getOrganizationsFn } from 'src/api'

export function OrganizationDetailsPage() {
  const [tab, setTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { userId, orgId } = useParams()

  useEffect(() => {
    if (location.state?.defaultTab) {
      setTab(location.state.defaultTab === 'users' ? 1 : 0)
    }
  }, [])

  const handleGroupClick = (groupId: number) => {
    setSearchParams({ groupID: groupId.toString() })
    setTab(1)
  }
  const navigate = useNavigate()

  const { data: orgs } = useQuery({
    queryKey: 'organizations',
    queryFn: () => getOrganizationsFn(),
    keepPreviousData: true,
  })

  const { data: groups } = useQuery({
    queryKey: ['orgGroups', orgId],
    queryFn: () => {
      if (!orgId) return
      return getOrgGroupsFn({ orgId })
    },
    keepPreviousData: true,
  })

  const orgName = orgs?.find((item) => item.id == Number(orgId))?.name
  const groupName = groups?.find(
    (item) => item.groupId == Number(searchParams.get('groupID'))
  )?.groupName
  const handleNavigateBackToOrgList = () => {
    const params = new URLSearchParams()
    if (orgName) params.append('org_name', orgName)

    const basePath = `/${userId}/sym-admin/organizations/exists`
    const fullPath = `${basePath}?${params.toString()}`

    navigate(fullPath)
  }

  const handeleNavigateBackToGroupList = (groupName: string | undefined) => {
    if (groupName) {
      const basePath = `/${userId}/sym-admin/organizations/exists/${orgId}`
      navigate(basePath)
      setTab(0)
    }
  }

  return (
    <RequireRole role={USER_ROLES.SYM_ADMIN}>
      <Box pt={2}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={handleNavigateBackToOrgList}
            color="#666666"
          >
            Organizations{' '}
          </Typography>
          <Typography
            sx={{ cursor: groupName && 'pointer' }}
            pl={1}
            color={groupName ? '#666666' : '#18191A'}
            onClick={() => handeleNavigateBackToGroupList(groupName)}
          >
            {' '}
            / {orgName}
          </Typography>
          {groupName && (
            <Typography pl={1} color="#18191A">
              / {groupName}
            </Typography>
          )}
        </Box>
        {tab === 0 && (
          <TabPanel>
            <GroupsTab handleGroupClick={handleGroupClick} />
          </TabPanel>
        )}
        {tab === 1 && (
          <TabPanel>
            <UsersTab />
          </TabPanel>
        )}
      </Box>
    </RequireRole>
  )
}

type TabPanelProps = {
  children: JSX.Element
}

function TabPanel({ children }: TabPanelProps) {
  return <Box pt={2}>{children}</Box>
}

export default OrganizationDetailsPage
