import { apiRequest } from '../api-request';
import { ENDPOINTS } from '../constants';

export const getOrgGroupsFn = async ({ orgId }: GetOrganizationGroupsInput) => {
  const { data } = await apiRequest.get<GetOrganizationGroupsResponse>(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/groups`
  );
  return data;
};

export const createGroupFn = async ({
  orgId,
  ...createGroupDetails
}: CreateGroupInput) => {
  await apiRequest.post(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/groups`,
    createGroupDetails
  );
};

export const updateGroupFn = async ({
  groupId,
  ...updateGroupDetails
}: UpdateGroupInput) => {
  await apiRequest.put(ENDPOINTS.GROUPS + `/${groupId}`, updateGroupDetails);
};

export const DeleteGroupFn = async ({
  groupId,
  orgId
}: DeleteGroupInput) => {
  await apiRequest.delete(ENDPOINTS.GROUPS + `/${orgId}` + `/${groupId}`);
};

export const createGroupUserFn = async ({
  orgId,
  ...createGroupUserDetails
}: CreateGroupUserInput) => {
  await apiRequest.post(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/create-user-group`,
    createGroupUserDetails
  );
};

export const updateGroupUserFn = async ({
  orgId,
  ...updateGroupUserDetails
}: UpdateGroupUserInput) => {
  await apiRequest.put(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/update-user-group`,
    updateGroupUserDetails
  );
};

export const setNewUserGroupFn = async ({
                                          orgId,
                                          ...updateGroupUserDetails
                                        }: UpdateGroupUserInput) => {
  await apiRequest.put(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/set-new-user-group`,
    updateGroupUserDetails
  );
};

export const updateGroupUserStatusFn = async ({
  orgId,
  ...updateUserDetails
}: UpdateUserOrgStatus) => {
  await apiRequest.post(
    ENDPOINTS.ORGANIZATIONS + `/${orgId}/update-user-org-status`,
    updateUserDetails
  );
};

type GetOrganizationGroupsInput = {
  orgId: number | string;
};

type GetOrganizationGroupsResponse = Array<{
  groupId: number;
  groupName: string;
  policies: {
    f_sequence_length_allowed: number;
    f_symbol_location_allowed: number;
    f_combo_allowed: number;
  };
  usersCount: number;
}>;

type CreateGroupInput = {
  orgId: number | string;
  groupName: string;
  policies: {
    f_sequence_length_allowed: number | null;
    f_symbol_location_allowed: number | null;
    f_combo_allowed: number | null;
  };
};

type UpdateGroupInput = {
  groupId: number | string;
  groupName: string;
  f_sequence_length_allowed: number | null;
  f_symbol_location_allowed: number | null;
  f_combo_allowed: number | null;
};

type UpdateUserOrgStatus = {
  orgId: number | string;
  groupID: number;
  userId: number | string;
  status: string;
}

type CreateGroupUserInput = {
  orgId: number | string;
  groupID: number;
  first_name: string;
  last_name: string;
  country_code: string;
  phone: string | number;
  email: string;
  is_org_admin: boolean;
};

type DeleteGroupInput = {
  orgId: number | string;
  groupId: number | string;
};

type UpdateGroupUserInput = {
  orgId: number | string;
} & Partial<{
  userId: number;
  groupId: number;
  first_name: string;
  last_name: string;
  country_code: string;
  phone: string | number;
  email: string;
  is_org_admin: boolean;
  status: string;
}>;

type SetNewGroupUserInput = {
  orgId: number | string;
} & Partial<{
  userId: number;
  groupId: number;
  is_org_admin: boolean;
  status: string;
}>;
