import React from 'react'

export function DarkLogo() {
  return (
    <img
      className="object-contain"
      width={250}
      height={64}
      src="./dark-logo.png"
      alt="logo"
    />
  )
}

export function LightLogo() {
  return (
    <img
      className="object-contain"
      width={186}
      height={60}
      src="./light-logo.png"
      alt="logo"
    />
  )
}

export function SmallLogo() {
  return (
    <img
      className="object-contain"
      width={130}
      height={42}
      src="./light-logo.png"
      alt="logo"
    />
  )
}

export function BackButton({
  width,
  height,
}: {
  width: string
  height: string
}) {
  return (
    <img
      className="object-contain"
      width={width}
      height={height}
      src="./back-button.png"
      alt="logo"
    />
  )
}

export function AccessLogo() {
  return (
    <img
      className="object-contain"
      width={40}
      height={40}
      src="./access.png"
      alt="logo"
    />
  )
}
