import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import LoadingIndicator from 'src/components/LoadingIndicator'
import UpdateUserDetailsForm from 'src/components/UpdateUserDetailsForm'
import UpdateUserEmailForm from 'src/components/UpdateUserEmailForm'
import UpdateUserPhoneForm from 'src/components/UpdateUserPhoneForm'
import SectionContainer from 'src/dashboard/components/SectionContainer'
import { useUserDetails } from 'src/providers/UserDetailsContext'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/providers/AuthProvider'
import { deleteUserAccount } from 'src/api/services/deleteUserAccount.service'
import { decryptFn } from 'src/share/utils/decrypt'
import { ContainedButton } from 'src/components/Button'
import { Images } from 'src/assets'
import {
  getCategorySymbolsFn,
  getSymbolCategoriesFn,
  ISymbol,
  SymbolCategory,
  updateUserSymbolFn,
} from 'src/api'
import { useMutation } from 'react-query'
import SecurityQuestionsForm from 'src/components/SecurityQuestionsForm'
import { useLocation } from 'react-router-dom'
import CreateOrgByUser from '../../organizations/CreateOrgByUser'
import { Height } from '@mui/icons-material'

function Profile() {
  const { user, logout } = useAuth()
  const userID = user?.id
  const { userDetails, refetchUserDetails } = useUserDetails()
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const [step, setStep] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const regPhase = userDetails?.regPhase
  const organisationsEmails =userDetails?.organizationEmails;
  console.log(organisationsEmails,"Hello")
  const [symbolCategories, setSymbolCategories] = useState<SymbolCategory[]>()
  const [selectedSymbol, setSelectedSymbol] = useState<ISymbol>()
  const [selectedSymbolCategoryIndex, setSelectedSymbolCategoryIndex] =
    useState<number>(0)
  const [sequence, setSequence] = useState<number>()
  const [matrixSize, setMatrixSize] = useState<string>('3x4')
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [isOrgFormOpen, setIsOrgFormOpen] = useState(false)
  const location = useLocation()

  const newEmail = location.state?.newEmail
  useEffect(() => {
    if (newEmail) setIsOrgFormOpen(true)
  }, newEmail)
  const closeOrgModal = () => setIsOrgFormOpen(false)

  const handleDeleteClick = async (userID: string) => {
    await deleteUserAccount({ userID })
    handleClose()
    refetchUserDetails()
  }

  const style = {
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    textAlign: 'center',
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    refetchUserDetails()
  }
  const handleBackStep = () => setStep(step - 1)

  useEffect(() => {
    if (userID) setUserId(Number(userID))
    getSymbolCategoriesFn({ includeEmpty: true }).then((res) => {
      // const filteredCategories = res.filter(
      //   (category) => (category.symbol_count ?? 0) >= 16
      // )

      setSymbolCategories(res)
    })
  }, [])

  const { mutate: updateUserSymbol, isLoading: updateSymbolLoading } =
    useMutation(updateUserSymbolFn, {
      onSuccess: () => {
        setHasChanged(false)
      },
    })

  const { mutate: symbolsMutate } = useMutation(getCategorySymbolsFn, {
    onSuccess(data, variables, context) {
      if (symbolCategories) {
        const { index } = variables
        symbolCategories[index].symbols = data
        setSymbolCategories([...symbolCategories])
      }
      return
    },
  })

  useEffect(() => {
    if (
      symbolCategories &&
      !symbolCategories[selectedSymbolCategoryIndex].symbols
    ) {
      symbolsMutate({
        categories: symbolCategories,
        index: selectedSymbolCategoryIndex,
      })
    }
  }, [selectedSymbolCategoryIndex, symbolCategories])

  const handleChangeSequence = (event: SelectChangeEvent) => {
    setSequence(parseInt(event.target.value))
  }

  const handleChangeMatrixSize = (event: React.MouseEvent<HTMLLIElement>) => {
    const value = event.currentTarget.getAttribute('value')
    if (value) {
      setMatrixSize(value)
    }
  }

  const colors = [
    '#918B84',
    '#E03A31',
    '#F8F8F8',
    '#A2F9F1',
    '#B450E9',
    '#A26029',
    '#F6842B',
    '#8C964C',
    '#F2A5DD',
    '#80F25C',
    '#FFE55B',
    '#4783FF',
    '#FFD700',
    '#7FFF00',
    '#456F34',
    '#B42D2D',
  ]

  const selectedCategory =
    symbolCategories && symbolCategories[selectedSymbolCategoryIndex]

  if (!userDetails)
    return (
      <SectionContainer>
        <LoadingIndicator variant="fullWidth" />
      </SectionContainer>
    )

  return (
    <>
      <Grid
        sx={{
          '@media (max-width: 1475px)': {
            paddingLeft: '150px',
          },
          '@media (max-width: 1380px)': {
            paddingLeft: '200px',
          },
          '@media (max-width: 1265px)': {
            paddingLeft: '300px',
          },
        }}
        container
        gap={2}
      >
        <Grid item xs={10} sx={{ maxWidth: 600 }}>
          <Typography
            pl={1}
            pt={6}
            pb={2}
            sx={{ color: '#111111', fontWeight: 'bold', marginTop: '40px' }}
          >
            Your personal details
          </Typography>
          <UpdateUserDetailsForm
            firstName={decryptFn(userDetails.firstName)}
            lastName={decryptFn(userDetails.lastName)}
            onSubmit={refetchUserDetails}
          />
          <UpdateUserPhoneForm
            countryCode={userDetails.countryCode.replace('+', '')}
            phone={decryptFn(userDetails.phoneNumber.toString())}
            onSubmit={refetchUserDetails}
          />
          <UpdateUserEmailForm
            email={decryptFn(userDetails.email)}
            onSubmit={refetchUserDetails}
          />
          {organisationsEmails && organisationsEmails?.length > 0 ? (
          <Grid
            pl={1}
            pt={3}
            pb={2}>
            <Typography sx={{ color: '#111111', fontWeight: 'bold' }}>Organization emails</Typography>
            {organisationsEmails?.map((email, index) => {
              if (email !== decryptFn(userDetails.email)) {
                return <Typography py={0.5} px={1} key={index}>{email}</Typography>;
              }
              return null;
            })}
          </Grid>)
            : null
          }



          <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleOpen}
              startIcon={<DeleteIcon />}
              size="small"
              sx={{
                borderRadius: '12px',
                fontSize: '16px',
              }}
              disabled={userDetails.selfDeletedAt !== null}
            >
              delete account
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: 'bold' }}
            >
              Are you sure you want to delete your account?
            </Typography>
            <Typography variant="h6">
              Please note that by continuing with the process, your data will be
              saved for 30 days. Afterwards, all your details will be
              permanently deleted from Symania's system.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '30px',
                marginTop: '10px',
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (userID) {
                    handleDeleteClick(userID)
                  }
                }}
                sx={{
                  borderRadius: '12px',
                  fontSize: '16px',
                }}
              >
                delete
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  borderRadius: '12px',
                  fontSize: '16px',
                }}
              >
                cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <div>
        {regPhase === 1 && step < 6 ? (
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '1000px',
                height: '840px',
                // minWidth: '900px',
                // minHeight: '820px',
                bgcolor: 'background.paper',
                border: '2px solid #fff',
                boxShadow: 24,
                borderRadius: '24px',
                textAlign: 'center',
              }}
            >
              {step < 5 ? (
                <Box
                  sx={{
                    paddingY: '20px',
                    paddingX: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 18,
                    borderBottom: '1px solid #DCDBE8',
                    color: '#7B7B82',
                  }}
                >
                  {step > 0 && step < 5 ? (
                    <Button
                      sx={{ cursor: 'pointer', padding: 0, minWidth: '28px' }}
                      onClick={handleBackStep}
                    >
                      <img width="28" height="28" src={Images.icArrow} />
                    </Button>
                  ) : (
                    <div style={{ width: '64px' }}></div>
                  )}

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h6"
                    sx={{
                      marginX: 'auto',
                    }}
                    className="font-inter text-[18px]"
                  >
                    {step === 0
                      ? 'Set your account for Symania'
                      : step === 1
                      ? 'Step 1 of 4'
                      : step === 2
                      ? 'Step 2 of 4'
                      : step === 3
                      ? 'Step 3 of 4'
                      : step === 4
                      ? 'Step 4 of 4'
                      : ''}
                  </Typography>

                  <Button
                    sx={{ cursor: 'pointer', padding: 0, minWidth: '28px' }}
                    onClick={handleCloseModal}
                  >
                    <img width="28" height="28" src={Images.icCloseBtn} />
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  paddingX: 6,
                  paddingY: 4,
                }}
              >
                {step === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: '118px',
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: '28px',
                      }}
                    >
                      <img width="60" height="60" src={Images.icHiHand} />
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h4"
                      sx={{
                        marginBottom: 1,
                        fontWeight: 600,
                        fontSize: 32,
                      }}
                      className="font-inter"
                    >
                      Welcome to Symania!
                    </Typography>
                    <Typography
                      id="modal-modal-text"
                      variant="h6"
                      component="h6"
                      sx={{
                        marginBottom: '48px',
                        fontWeight: 400,
                        fontSize: 18,
                      }}
                    >
                      Just few more steps to set your account
                    </Typography>
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        gap: '50px',
                      }}
                    >
                      <ContainedButton
                        onClick={() => setStep(1)}
                        title="Let’s start"
                        style={{
                          width: '240px',
                          backgroundColor: '#FB3B8C',
                          color: 'white',
                          borderRadius: '12px',
                          fontSize: '16px',
                        }}
                        className="rounded-xl font-inter"
                      />
                    </Box>
                  </Box>
                ) : step === 1 ? (
                  <Box>
                    <Box>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h4"
                        sx={{
                          marginBottom: 1,
                          fontWeight: 600,
                          fontSize: 32,
                        }}
                      >
                        Choose your symbol
                      </Typography>
                      <Typography
                        id="modal-modal-text"
                        variant="h6"
                        component="h6"
                        sx={{
                          marginBottom: '48px',
                          fontSize: 18,
                        }}
                      >
                        Start by choosing a catalog of symbols according to your
                        desired theme.
                        <br />
                        Then, choose your symbol, this will be your secret
                        symbol to guide you through all your logins.
                      </Typography>
                    </Box>
                    <Grid
                      sx={{
                        justifyContent: 'space-between',
                        marginBottom: '40px',
                      }}
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{
                          marginLeft: '3vw',
                        }}
                      >
                        <Typography
                          fontSize={18}
                          fontWeight={'bold'}
                          marginBottom={1}
                          textAlign={'start'}
                        >
                          Catalogs
                        </Typography>
                        <Box
                          className="scroll"
                          sx={{
                            scrollbarWidth: 'thin',
                            overflowY: 'auto',
                          }}
                        >
                          <MenuList>
                            {symbolCategories &&
                              symbolCategories.map((category, index) => (
                                <MenuItem
                                  sx={{
                                    '&.Mui-selected': {
                                      backgroundColor:
                                        selectedSymbolCategoryIndex === index
                                          ? '#FFF7FA'
                                          : 'white',

                                      border: '1px solid #FB3B8C',
                                    },
                                    marginTop: '12px',
                                    border: '1px solid #D2DAE8',
                                    borderRadius: '8px',
                                  }}
                                  key={category.catalogID}
                                  onClick={() =>
                                    setSelectedSymbolCategoryIndex(index)
                                  }
                                  selected={
                                    selectedSymbolCategoryIndex === index
                                  }
                                >
                                  <ListItemText
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      height: '40px',
                                      justifyContent: 'space-between',
                                    }}
                                    primary={category.catalog_name}
                                    secondary={`(${category.symbol_count})`}
                                    secondaryTypographyProps={{
                                      color: '#111111',
                                    }}
                                  />
                                </MenuItem>
                              ))}
                          </MenuList>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={0.1}>
                        <Divider
                          sx={{
                            borderColor: '#D2DAE8',
                            width: '1px',
                          }}
                          orientation="vertical"
                        />
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                          >
                            {selectedCategory && selectedCategory?.catalog_name}
                          </Box>
                          <Typography
                            sx={{ color: '#7B7B82', marginRight: '12px' }}
                          >{`${
                            selectedCategory?.symbols?.length ?? 0
                          } Symbols `}</Typography>
                        </Box>

                        <Box
                          className="scroll"
                          sx={{
                            scrollbarWidth: 'thin',
                            overflowY: 'auto',
                            height: '395px',
                            width: '544px',
                          }}
                        >
                          <Grid mt={2} container spacing={2}>
                            {selectedCategory?.symbols ? (
                              selectedCategory.symbols.map((s) => {
                                console.log(decryptFn(s.symbol))
                                return (
                                  <Grid item xs={12} md={3} key={s.symbolID}>
                                    <Button
                                      onClick={() => {
                                        if (
                                          s.symbolID ===
                                          selectedSymbol?.symbolID
                                        ) {
                                          setSelectedSymbol(undefined)
                                          return
                                        }
                                        setSelectedSymbol(s)
                                      }}
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                        width: '110px',
                                        height: '110px',
                                        border:
                                          s.symbolID ===
                                          selectedSymbol?.symbolID
                                            ? '2px solid #FB3B8C'
                                            : '1px solid #DCDBE8',
                                        background:
                                          s.symbolID ===
                                          selectedSymbol?.symbolID
                                            ? '#FFF7FA'
                                            : 'white',
                                        color: selectedSymbol?.symbolID
                                          ? '#FB3B8C'
                                          : 'black',
                                      }}
                                    >
                                      <div>
                                        <img
                                          style={{
                                            maxWidth: '100%',
                                            aspectRatio: 1,
                                            width: '44px',
                                          }}
                                          src={decryptFn(s.symbol)}
                                          alt={s.symbolID.toString()}
                                        />
                                      </div>
                                    </Button>
                                  </Grid>
                                )
                              })
                            ) : (
                              <div className="flex w-full h-full items-center justify-center">
                                <CircularProgress color="inherit" />
                              </div>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <ContainedButton
                      // ref={refContainedButton}
                      onClick={() => {
                        setStep(2)
                      }}
                      style={{
                        backgroundColor: '#FB3B8C',
                        color: 'white',
                      }}
                      disabled={!selectedSymbol}
                      title="Next"
                      className="w-40"
                    />
                  </Box>
                ) : step === 2 ? (
                  <Box>
                    <Box>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h4"
                        sx={{
                          marginBottom: 1,
                          fontWeight: 600,
                          fontSize: 32,
                        }}
                      >
                        Set your sequence
                      </Typography>
                      <Typography
                        id="modal-modal-text"
                        variant="h6"
                        component="h6"
                        sx={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginBottom: '32px',
                          fontSize: 18,
                          maxWidth: '550px',
                        }}
                      >
                        The sequence number determines how many times you'll
                        need to click on your phone's app to authenticate.
                      </Typography>
                    </Box>
                    <FormControl
                      sx={{
                        textAlign: 'start',
                        padding: '0',
                        width: '400px',
                        marginBottom: '40px',
                        '& .MuiInputLabel-root': {
                          color: '#93929B',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#93929B',
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Sequence
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sequence?.toString()}
                        label="Sequence"
                        margin="none"
                        onChange={handleChangeSequence}
                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'inherit',
                          },
                        }}
                      >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                      </Select>
                    </FormControl>
                    <ContainedButton
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: '#FB3B8C',
                        color: 'white',
                      }}
                      disabled={sequence === undefined}
                      onClick={() => setStep(3)}
                      className="w-40"
                      title="Next"
                    />
                  </Box>
                ) : step === 3 ? (
                  <Box>
                    <Box>
                      <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h4"
                        sx={{
                          marginBottom: 1,
                          fontWeight: 600,
                          fontSize: 32,
                        }}
                      >
                        Select the matrix size
                      </Typography>
                      <Typography
                        id="modal-modal-text"
                        variant="h6"
                        component="h6"
                        sx={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginBottom: '60px',
                          fontSize: 18,
                        }}
                      >
                        The matrix size determines the number of symbols
                        displayed during login.
                        <br />
                        The location of your symbol and its background color
                        will be random in each login.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginBottom: '40px',
                        maxHeight: '42vh',
                      }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography
                          fontSize={18}
                          fontWeight={'bold'}
                          marginBottom={1}
                          textAlign={'start'}
                        >
                          Matrix size
                        </Typography>
                        <MenuList>
                          {['3x3', '3x4', '4x4'].map((size) => (
                            <MenuItem
                              sx={{
                                '&.Mui-selected': {
                                  backgroundColor: '#FFF7FA',
                                  border: '1px solid #FB3B8C',
                                },
                                marginTop: '12px',
                                width: '240px',
                                height: '52px',
                                border: '1px solid #D2DAE8',
                                borderRadius: '8px',
                                justifyContent: 'center',
                              }}
                              key={size}
                              value={size}
                              onClick={handleChangeMatrixSize}
                              selected={matrixSize === size}
                            >
                              {size}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Grid>
                      <Grid item xs={12} md={0.1}>
                        <Divider
                          sx={{
                            borderColor: '#D2DAE8',
                            width: '1px',
                          }}
                          orientation="vertical"
                        />
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Box>
                          <Grid
                            mt={2}
                            container
                            spacing={2}
                            width={matrixSize === '4x4' ? '20.5vw' : '16vw'}
                            padding={'16px 16px 32px 16px'}
                            border={'1px solid #D2DAE8'}
                            borderRadius={'24px'}
                            sx={{ maxHeight: '42vh' }}
                          >
                            {selectedCategory?.symbols ? (
                              selectedCategory.symbols
                                .slice(
                                  0,
                                  matrixSize === '3x3'
                                    ? 9
                                    : matrixSize === '3x4'
                                    ? 12
                                    : 16
                                )
                                .map((s, i) => {
                                  const symbolsNumber = matrixSize
                                    .split('x')
                                    .map((el) => Number(el))
                                  if (i === symbolsNumber[0] * symbolsNumber[1])
                                    return
                                  console.log(decryptFn(s.symbol))
                                  return (
                                    <Grid
                                      item
                                      xs={matrixSize === '4x4' ? 3 : 4}
                                      md={matrixSize === '4x4' ? 3 : 4}
                                      key={s.symbolID}
                                    >
                                      <Box
                                        sx={{
                                          alignItems: 'center',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          borderRadius: '20px',
                                          width: '3.5vw',
                                          height: '3.5vw',
                                          border: '1px solid #DCDBE8',
                                          background: colors[i],
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxWidth: '100%',
                                            aspectRatio: 1,
                                            width: '37px',
                                          }}
                                          src={decryptFn(s.symbol)}
                                          alt={s.symbolID.toString()}
                                        />
                                      </Box>
                                    </Grid>
                                  )
                                })
                            ) : (
                              <div className="flex w-full h-full items-center justify-center">
                                <CircularProgress color="inherit" />
                              </div>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                    <ContainedButton
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: '#FB3B8C',
                        color: 'white',
                      }}
                      onClick={() => setStep(4)}
                      className="w-40"
                      title="Next"
                    />
                  </Box>
                ) : step === 4 ? (
                  <Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h4"
                      sx={{
                        marginBottom: 1,
                        fontWeight: 600,
                        fontSize: 32,
                      }}
                    >
                      Security questions
                    </Typography>
                    <Typography
                      id="modal-modal-text"
                      variant="h6"
                      component="h6"
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '32px',
                        maxWidth: '500px',
                        fontSize: 18,
                      }}
                    >
                      For your security, please answer the following questions.
                      These questions will help us verify your identity in case
                      you have trouble logging in or your phone is stolen.
                    </Typography>
                    <SecurityQuestionsForm
                      step={step}
                      onSubmit={() => {
                        if (
                          userId &&
                          selectedSymbol &&
                          sequence !== undefined &&
                          matrixSize !== undefined
                        ) {
                          const [matrixSizeW, matrixSizeH] = matrixSize
                            .split('x')
                            .map((val) => parseInt(val))
                          updateUserSymbol({
                            userId: userId.toString(),
                            symbol: selectedSymbol.symbolID,
                            sequence,
                            matrixSizeH,
                            matrixSizeW,
                          })
                        }
                        setStep(5)
                      }}
                      userId={userId}
                    />
                  </Box>
                ) : step === 5 ? (
                  <Box
                    sx={{
                      paddingTop: '204px',
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: '28px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img width="60" height="60" src={Images.icOkay} />
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h4"
                      sx={{
                        marginBottom: 1,
                        fontWeight: 600,
                        fontSize: 32,
                      }}
                    >
                      Your account has been successfully set up!
                    </Typography>
                    <Typography
                      id="modal-modal-text"
                      variant="h6"
                      component="h6"
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '32px',
                        maxWidth: '500px',
                        fontSize: 18,
                      }}
                    >
                      A text message with a link to Install our app has been
                      sent to your phone. Install SyMania’s App and start login.
                    </Typography>
                    <ContainedButton
                      type="submit"
                      style={{ backgroundColor: '#FB3B8C', color: 'white' }}
                      onClick={() => {
                        setStep(6)
                        window.location.reload()
                      }}
                      className="w-[200px]"
                      title="Close"
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Modal>
        ) : (
          <></>
        )}
      </div>
      <CreateOrgByUser
        userName={
          decryptFn(String(userDetails.firstName)) +
          ' ' +
          decryptFn(String(userDetails.lastName))
        }
        email={decryptFn(String(userDetails.email))}
        isOpen={isOrgFormOpen}
        newEmail={newEmail}
        onClose={closeOrgModal}
      />
    </>
  )
}

export default Profile
