const colorMapping: Record<string, string> = {
  a: '#b3d9ff',
  b: '#e6e6fa',
  c: '#b0b0b0',
  d: '#d2b48c',
  e: '#f5f5dc',
  f: '#9aceeb',
  g: '#93e9be',
  h: '#f08080',
  i: '#ff80be',
  j: '#9000cc',
  k: '#e718e7',
  l: '#6b82e2',
  m: '#9c96ee',
  n: '#2ba2ce',
  o: '#6b82e2',
  p: '#c072aa',
  q: '#fdd805',
  r: '#bc86e0',
  s: '#fceee2',
  t: '#6191db',
  u: '#5a5fcf',
  v: '#927bbd',
  w: '#ffc0cb',
  x: '#fe6f47',
  y: '#cfe8a3',
  z: '#755faa',
}

export const getInitialsAndColor = (firstName: string, lastName: string) => {
  const initials =
    firstName.split('')[0]?.toUpperCase() + lastName.split('')[0]?.toUpperCase()
  const color = colorMapping[initials[0].toLowerCase()] || '#ccc'
  return { initials, color }
}
