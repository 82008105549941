import axios from 'axios'
import { BASE_URL, ENDPOINTS } from './constants'

export const apiRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

apiRequest.defaults.headers.common['Content-Type'] = 'application/json'

const refreshAccessToken = async () => {
  const { data } = await axios.post(
    `${BASE_URL}/${ENDPOINTS.TOKEN}/refresh`,
    null,
    { withCredentials: true }
  )
  return data
}

apiRequest.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    const errorStatus = error.response.status
    if (
      (errorStatus === 401 || errorStatus === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      try {
        await refreshAccessToken()
        return apiRequest(originalRequest)
      } catch (err: any) {
        if (err.response.data.message === 'Token expired') {
          window.location.href = '/logout'
          return
        }
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)
