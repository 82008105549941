import { apiRequest } from '../api-request'
import { ENDPOINTS } from '../constants'

export const addEmailFn = async ({ email, userId }: AddEmailInput) => {
  const { data } = await apiRequest.post<AddEmailResponse>(ENDPOINTS.EMAILS, {
    email,
    userId,
  })
  return data.unique
}

export const validateEmailFn = async ({ unique, code }: ValidateEmailInput) => {
  await apiRequest.post(ENDPOINTS.CODES + '/validate', { unique, code })
}

export const validateRegisterEmailFn = async (token: string) => {
  return await apiRequest.get(
    ENDPOINTS.USERS + `/email-verification?token=${token}`
  )
}

export const addNewOrgEmailFn = async (email: string) => {
  return await apiRequest.post(ENDPOINTS.USERS + `/new-org-email-verification`)
}

export const validateNewOrgEmail = async (token: string) => {
  return await apiRequest.get(
    ENDPOINTS.USERS + `/new-org-email-verification?token=${token}`
  )
}

type AddEmailInput = {
  email: string
  userId?: number | null
}

type AddEmailResponse = {
  unique: string
}

type ValidateEmailInput = {
  unique: string
  code: string
}

export function emailLogin(email: string) {
  return apiRequest.post<{ token: string }>('users/login', {
    email,
  })
}

export function phoneLogin({
  country_code,
  phone,
}: {
  country_code: string
  phone: string
}) {
  return apiRequest.post<{ token: string }>('users/login', {
    country_code,
    phone,
  })
}
