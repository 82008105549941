import { AppBar, IconButton, Toolbar } from '@mui/material'
import { DarkLogo, LightLogo } from 'src/components/SymanisLogo'
import Close from '@mui/icons-material/Close'
import { useLocation, useNavigate } from 'react-router-dom'
import { OutlinedButton } from 'src/components/Button'
import { appColor } from 'src/share/colors'

interface AuthLayoutProps {
  children: React.ReactNode
}

function AuthLayout({ children }: AuthLayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  function handleHome() {
    if (location.pathname !== '/register') navigate('/')
  }
  function handleBack() {
    navigate(-1)
  }
  return (
    <div className="py-[80px] px-[100px] h-screen bg-hero bg-cover bg-no-repeat">
      <AppBar
        sx={{
          position: 'static',
          marginBottom: '52px',
          background: 'none',
          boxShadow: 'none',
        }}
      >
        <div className="cursor-pointer" onClick={handleHome}>
          <DarkLogo />
        </div>
      </AppBar>
      {children}
    </div>
  )
}

export default AuthLayout
