import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
  Link,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { MdMenu } from 'react-icons/md'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { activateGroupUserFn } from 'src/api'
import { Images } from 'src/assets'
import LoadingIndicator from 'src/components/LoadingIndicator'
import AuthLayout from 'src/layouts/AuthLayout'
import { useAuth } from 'src/providers/AuthProvider'
import { useUserDetails } from 'src/providers/UserDetailsContext'

type RedirectURL = {
  link: string
  state?: any
}

function ActivateGroupUserPage() {
  const { activateGroupUserToken } = useParams()
  const [invalidPage, setInvalidPage] = useState(false)
  const [redirectURL, setRedirectURL] = useState<RedirectURL | null>(null)
  const [message, setMessage] = useState<string>('')
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const { userDetails, refetchUserDetails } = useUserDetails()

  const { mutate: activateGroupUser, isLoading } = useMutation(
    activateGroupUserFn,
    {
      onSuccess: (orgName) => {
        setMessage(
          `Great you joined ${orgName} with the smart solution of Symania. From now on you can connect to your org systems with your selected symbol and sequence`
        )
        // Remove the token in session storage in success cases
        sessionStorage.removeItem('activate-group-user-token')
      },
      onError: (error: any) => {
        const { status, data } = error.response

        // Store the token in session storage in error cases for the following process to reuse to redirect to this page
        if (activateGroupUserToken) {
          sessionStorage.setItem(
            'activate-group-user-token',
            activateGroupUserToken
          )
        }
        if (status === 401) {
          setInvalidPage(true)
          // Remove the token if the page is invalid
          sessionStorage.removeItem('activate-group-user-token')
        }
        if (status === 400) {
          const { reason } = data
          if (reason === 'reg_process') {
            setRedirectURL({
              link: `/register`,
              state: {
                minSequence: data.minSequence,
                activateToken: activateGroupUserToken,
                userId: data.userId,
              },
            })
            setMessage(
              `You need to complete your registration process to join ${data.orgName}`
            )
          }
          if (reason === 'locked') {
            setRedirectURL({ link: `/reset-secrets` })
            setMessage(
              `We noticed you joined ${data.orgName} but you are currently blocked. Continue to unlock yourself.`
            )
          }
          if (reason === 'sequence') {
            setRedirectURL({
              link: `/activate-group-user/${activateGroupUserToken}/update-sequence`,
              state: {
                minSequence: data.minSequence,
              },
            })
            setMessage(
              `According to ${data.orgName} terms of use, you need to update your sequence for a minimum of ${data.minSequence}`
            )
          }
        }
      },
    }
  )

  useEffect(() => {
    if (!activateGroupUserToken) {
      setInvalidPage(true)
      return
    }
    activateGroupUser({ token: activateGroupUserToken })
  }, [])

  const goToRedirectURL = () => {
    if (!redirectURL) return
    navigate(redirectURL.link, { state: redirectURL.state })
  }

  if (invalidPage) {
    return <h1>Page is invalid</h1>
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <Box
      sx={{
        margin: '0 auto',
        backgroundColor: 'white',
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '0px 32px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundImage: `url(${Images.icBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '109px',
          width: '100%',
        }}
      >
        <IconButton>
          <img src={Images.icLogoSymania} width={195} height={40} />
        </IconButton>
      </Box>
      <Box
        mt={8}
        sx={{
          width: '635px',
          height: '344px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '97px 147px 97px 147px',
          borderRadius: '8px',
          border: '1px solid #DCDBE8',
        }}
      >
        <Typography
          fontSize="18px"
          textAlign="center"
          fontWeight="bold"
          variant="body1"
          component="p"
        >
          {message}
        </Typography>
        {redirectURL ? (
          <Button
            sx={{
              marginTop: 5,
              borderRadius: '12px',
              height: '44px',
              width: '113px !important',
              '&:hover': { backgroundColor: '#111111' },
              backgroundColor: '#111111',
            }}
            variant="contained"
            onClick={goToRedirectURL}
          >
            Continue
          </Button>
        ) : (
          <Button
            sx={{
              marginTop: 5,
              borderRadius: '12px',
              height: '44px',
              width: '113px !important',
              '&:hover': { backgroundColor: '#111111' },
              backgroundColor: '#111111',
            }}
            href="/"
            variant="contained"
          >
            Log in
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ActivateGroupUserPage
