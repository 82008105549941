import Profile from './views/settings/profile/Profile'
import SymaniaSettings from './views/settings/symania/SymaniaSettings'
import SecuritySettings from './views/settings/security/SecuritySettings'
import Dashboard from './views/dashboard/Dashboard'
import OrganizationsHome from './views/organizations/OrganizationsHome'
import OrganizationDetailsPage from './views/organizations/OrganizationDetailsPage'
import GroupsPage from './views/organizations/GroupsPage'
import OrgUsersPage from './views/organizations/UsersPage'
import Policies from './views/settings/policies/Policies'
import ApiLogs from './views/logs/ApiLogs'
import AuditLogs from './views/logs/AuditLogs'
import EmailHistory from './views/logs/EmailHistory'
import UsersPage from './views/users/UsersPage'
import GeneralSettings from './views/general-settings/GeneralSettings'
import ColorsSettings from './views/colors/ColorsSettings'
import SymbolsSettings from './views/symbols/SymbolsSettings'
import SubscriptionsSettings from './views/subscriptions/SubscriptionsSettings'
import ServiceProviderPage from './views/saml/ServiceProvider'
import OAuthClientPage from './views/oauth/OAuthClient'
import Devices from './views/settings/devices/Devices'
import SubscriptionOrgAdmin from './views/subscriptions/SubscriptionOrgAdmin'
import Organizations from './views/settings/organizations/Organizations'

export const personalRoutes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/settings/profile', name: 'Profile', element: Profile },
  {
    path: '/settings/symania',
    name: 'Symania Settings',
    element: SymaniaSettings,
  },
  {
    path: '/settings/questions',
    name: 'Security Settings',
    element: SecuritySettings,
  },
  // {
  //   path: '/settings/policies',
  //   name: 'Policies',
  //   element: Policies,
  // },
  {
    path: '/settings/devices',
    name: 'Devices',
    element: Devices,
  },
  {
    path: '/settings/organizations',
    name: 'Organizations',
    element: Organizations,
  },
]

export const symAdminRoutes = [
  {
    path: '/users',
    name: 'Users',
    element: UsersPage,
  },
  {
    path: '/organizations/exists',
    name: 'Organizations',
    element: OrganizationsHome,
  },
  {
    path: '/organizations/requests',
    name: 'Organizations',
    element: OrganizationsHome,
  },
  {
    path: '/organizations/exists/:orgId',
    name: 'Organization Details',
    element: OrganizationDetailsPage,
  },
  {
    path: '/logs/api',
    name: 'API Logs',
    element: ApiLogs,
  },
  {
    path: '/logs/audit',
    name: 'Audit Logs',
    element: AuditLogs,
  },
  {
    path: '/logs/email-history',
    name: 'Email History',
    element: EmailHistory,
  },
  // {
  //   path: '/settings',
  //   name: 'General Settings',
  //   element: GeneralSettings,
  // },
  {
    path: '/colors-settings',
    name: 'Colors Settings',
    element: ColorsSettings,
  },
  {
    path: '/symbols-settings',
    name: 'Symbols Settings',
    element: SymbolsSettings,
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    element: SubscriptionsSettings,
  },
]

export const orgAdminRoutes = [
  {
    path: '/',
    name: 'Organization Admin Dashboard',
    element: Dashboard,
  },
  {
    path: '/groups',
    name: 'Groups',
    element: GroupsPage,
  },
  {
    path: '/users',
    name: 'Users',
    element: OrgUsersPage,
  },
  {
    path: '/service-providers',
    name: 'Service Providers',
    element: ServiceProviderPage,
  },
  {
    path: '/oauth-clients',
    name: 'OAuth Clients',
    element: OAuthClientPage,
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    element: SubscriptionOrgAdmin,
  },
]
