import React, { createContext, useContext, useState } from 'react'
import { decodeJwt } from 'jose'

export interface User {
  id: string
  firstName: string
  lastName: string
  roles: string[]
}

interface AuthContextValue {
  user: User | null
  login: (userToken: string, onLogin?: (userData: User) => void) => void
  logout: () => void
  isSameUser: (userToken: string) => boolean
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  isSameUser: () => false,
})

function useAuth() {
  return useContext(AuthContext)
}

interface AuthProviderProps {
  children: React.ReactNode
}

const USER_KEY = 'user'

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(
    sessionStorage.getItem(USER_KEY) !== null
      ? JSON.parse(sessionStorage.getItem(USER_KEY) as string)
      : null
  )

  const isSameUser = (userToken: string) => {
    const userDetails = decodeJwt(userToken)
    return userDetails.sub === user?.id
  }

  const login = (userToken: string, onLogin?: (userData: User) => void) => {
    const userDetails = decodeJwt(userToken)
    const userData = {
      id: userDetails.sub as string,
      firstName: userDetails.firstName as string,
      lastName: userDetails.lastName as string,
      roles: userDetails.roles as string[],
    }
    sessionStorage.setItem(USER_KEY, JSON.stringify(userData))
    setUser(userData)
    if (onLogin) {
      onLogin(userData)
    }
  }

  const logout = () => {
    setUser(null)
    sessionStorage.removeItem(USER_KEY)
  }

  const value: AuthContextValue = {
    user,
    login,
    logout,
    isSameUser,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuth }
